import 'core-js';
import $ from "jquery";
import select2 from 'select2';
import "bootstrap";
import "../sass/app.scss";
import 'datatables.net-bs4';

$(function () {
    $('#loading').fadeOut();;

    const clickableRows = $(".clickable-row");

    clickableRows.on("click", function () {
        let data = $(this).data("href");
        window.location = data;
    });

    const selects = $('select');

    if (selects.length > 0) {
        selects.each((i, el) => {
           if ($(el).hasClass('multiple')) {
                $(el).select2({
                    allowClear: true,
                    placeholder: $(el).attr('placeholder'),
                    defaultValue: null
                 });

                 $(el).attr('name', $(el).attr('name') + '[]');
                 $(el).children('option').first().remove();
            } else {
                $(el).select2();
            }
        })
    }

    const requestsTable = $('#requests-table');

    if (requestsTable.length > 0) {
        requestsTable.DataTable({
            "order": [[ 3, "desc" ]]
        });
    }
});
